import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ResponsiveSpacing from "../components/responsive-spacing"
import Inner from "../components/inner"
import Button from "../components/button"
import HeroArea from "../components/hero"
import Center from "../components/center"
import MoodPicker from "../components/mood-picker"
import BottomColorNav from "../components/bottom-color-nav"
import Carousel from "../components/carousel"
import Heading from "../components/heading"
import Img from "gatsby-image/withIEPolyfill"
import { Link, useStaticQuery, graphql } from "gatsby"
const postPath = require("../utils/post-path")

const MAX_AT_FIRST = 12

const RecipeStyled = styled.div`
  .blank {
    max-width: 260px;
    height: 260px;
    background: grey;
  }

  .img {
    max-width: 260px;
    height: 260px;
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  h3 {
    color: ${(p) => p.theme.Blue};
    font-family: "Montserrat";
    font-size: 1.4em;
    text-align: center;
    margin: 0;
    min-height: 50px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-transform: uppercase;

    @media (max-width: 768px) {
      max-width: 260px;
      margin: 0 auto;
    }
  }

  .link {
    text-decoration: none;
  }

  .infoIcon {
    width: 26px;
    cursor: pointer;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`

const Recipe = ({ dispatch, name, name_with_html, hero_image, index }) => {
  return (
    <RecipeStyled>
      <Link to={postPath("/recipe/", name)} className="link">
        {hero_image ? (
          <Img
            alt={name}
            fluid={hero_image.localFile.childImageSharp.fluid}
            className="img"
          />
        ) : (
          <div className="blank" />
        )}
        <h3
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: name_with_html || name,
          }}
        />
      </Link>
    </RecipeStyled>
  )
}

const RecipesStyled = styled.div`
  max-width: 1100px;
  margin: 50px auto;
  position: relative;
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1400px) {
    grid-gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Recipes = ({ dispatch, page, recipes }) => {
  const recipesHtml = recipes.map((r, index) => {
    const re = r.acf
    return (
      <Recipe
        name={r.title}
        {...re}
        dispatch={dispatch}
        key={index}
        index={index}
      />
    )
  })
  return <RecipesStyled>{recipesHtml}</RecipesStyled>
}

const ConnectedRecipes = connect((state) => state)(Recipes)

const RecipesPage = () => {
  const data = useStaticQuery(pageQuery)
  const [showMore, setShowMore] = useState(false)
  const [all, setAll] = useState(true)
  // const [recipe, setRecipe] = useState(false)
  const page = data.page.edges[0].node
  const flavors = data.flavors.edges.map((e) => e.node)
  const fields = page.acf
  const filterTags = fields.mood_picker.filters.map((f) => f.name)
  let filterReset = {}
  filterTags.map((name) => (filterReset[name] = false))
  const [selectedFilters, toggleFilter] = useState(filterReset)
  const allRecipes = data.recipes.edges.map((e) => e.node)
  const featuredRecipeID = fields.hero_area.recipe[0].wordpress_id
  const recipe = allRecipes.filter(
    (r) => r.wordpress_id === featuredRecipeID
  )[0]

  const trail = [
    {
      label: "FLAVORS",
      link: "/flavors/",
    },
    {
      label: "RECIPES",
      link: "/recipes/",
    },
    {
      label: recipe.title,
      link: "",
    },
  ]

  // Unused for choosing a random recipe.
  // useEffect(() => {
  //   setRecipe(allRecipes[Math.floor(Math.random() * allRecipes.length)])
  // }, [])

  const filtered = allRecipes
    .filter((r) => {
      const tags = r.acf.tags
      if (!tags) return r
      const keys = Object.keys(selectedFilters)
      const selectedKeys = keys.filter((k) => selectedFilters[k])
      const well = selectedKeys.map((tag) => tags.includes(tag))
      return well.includes(true) || all
    })
    .filter((f, i) => {
      return i < (showMore ? Infinity : MAX_AT_FIRST)
    })

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <HeroArea fields={fields} trail={trail}>
        <div className="middle">
          <Button to={postPath("/recipe/", recipe)}>GET RECIPE</Button>
        </div>
      </HeroArea>
      <ResponsiveSpacing>
        <Inner maxWidth="1100px">
          <Heading margin="50px 0 0 0">
            <h2>{fields.opening.headline}</h2>
            <h3>{fields.opening.sub_heading}</h3>
          </Heading>
          <MoodPicker
            fields={fields}
            selectedFilters={selectedFilters}
            toggleFilter={toggleFilter}
            all={all}
            setAll={setAll}
            filterReset={filterReset}
          />
        </Inner>
        <ConnectedRecipes recipes={filtered} page={page} />
        <Center>
          {!showMore ? (
            <Button
              icon="-"
              onClick={(e) => {
                setShowMore(true)
              }}
            >
              {fields.more_button_label}
            </Button>
          ) : (
            ""
          )}
        </Center>
        <Heading margin="150px 0 0 0">
          <h2>{fields.carousel.headline}</h2>
          <h3>{fields.carousel.sub_heading}</h3>
        </Heading>
      </ResponsiveSpacing>
      <Carousel flavors={flavors} />
      <Center margin="0px auto 150px auto">
        <Button to="/flavors">{fields.carousel.button_label}</Button>
      </Center>
      <BottomColorNav />
    </Layout>
  )
}

export default RecipesPage

const pageQuery = graphql`
  query {
    page: allWordpressPage(filter: { slug: { eq: "recipes-page" } }) {
      edges {
        node {
          title
          acf {
            hero_area {
              top_line
              top_line_color
              headline
              headline_color
              wave_color
              button_label
              recipe {
                wordpress_id
              }
              background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              mobile_background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            opening {
              headline
              sub_heading
            }
            mood_picker {
              call_to_action
              filters {
                name
                color
              }
            }
            search_title
            search_description
            share_headline
            share_comment
            share_image {
              source_url
            }
            more_button_label
            carousel {
              headline
              sub_heading
              button_label
            }
          }
        }
      }
    }
    recipes: allWordpressWpRecipes(sort: { fields: [date], order: DESC }) {
      edges {
        next {
          title
        }
        previous {
          title
        }
        node {
          id
          wordpress_id
          title
          acf {
            name_with_html
            color_1
            color_2
            summary
            servings
            flavor {
              wordpress_id
            }
            tags
            more_recipes {
              headline
              sub_heading
              button_label
              similar_recipes {
                wordpress_id
              }
            }
            ingredients {
              item
            }
            instructions {
              item
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2560, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2560, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            bottom_cta {
              headline
              sub_heading
              garnish {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    flavors: allWordpressWpFlavors(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          wordpress_id
          id
          title
          acf {
            flavor {
              name
              color_1
              color_2
              carton_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              inside_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            similar_items {
              headline
              sub_heading
              selected_flavors {
                wordpress_id
              }
            }
            recipe {
              featured_recipes {
                wordpress_id
              }
            }
          }
        }
      }
    }
  }
`
